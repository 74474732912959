.solutionTitle h1{
    font-size: 28px;
    padding-top: 40px;
    padding-bottom: 8px;
    margin-bottom: 22px;
    border-bottom: 2px solid #ddd;
    display: inline-block;
}
.solution_discription p{
    font-size: 17px;
    text-align: justify;
    line-height: 28px;
}
.solution_discription p strong{
    font-size: 17px;
}
.navbar {
    padding: 0rem 1rem;
}