body{
    overflow-x: hidden;
}
img{
	width: 100%;
}

/*============Navbar css============*/
.pageBg{
    position: relative;
    background-image: url('/src/assets/images/pagebg.png');
    margin-top: 0px;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 308px;
}
.PageTitle h3{
    bottom: 25%;
    position: absolute;
    left: calc(50% - 103px);
    color: #fff;
    font-weight: 700;
}
.navbar{
	position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;
}
.navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-size: 16px;
    font-family: inherit;
    font-weight: 600;
    padding-left: 14px;
    padding-right: 14px;
    text-transform: uppercase;
}
.navbar-light .navbar-nav .nav-link:hover{
	color: #FF4A17;
}
.sub_menu{
    position: relative;
}
.navbar-nav a ul{
    list-style: none;
    padding: 0px;
    position: absolute;
    width: 207px;
    margin-top: 8px;
    display: none;
}
.navbar-nav a ul li{
    background: #FF4A17;
    border-bottom: 1px solid #ddd;
    padding: 14px 16px 12px 17px;
    border-radius: 3px;
}
.navbar-nav a ul li:last-child{
    border-bottom: 0px solid #ddd;
}
.navbar-nav .nav-links{
    color: #fff;
    text-decoration: none;
}
.sub_menu:hover ul{
    display: block;
}
.navbar-brand{
	width: 16%;
}
.navbar-brand img{
	width: 100%;
}
.navbar button{
	padding: 7px 20px 10px 20px;
    font-size: 17px;
    font-weight: 500;
    border: 1px solid #fff;
    color: #fff;
    background-position:left bottom;
    transition:all 1s ease;
    text-transform: uppercase;
}
.navbar button:hover{
	background-position:left bottom;
	background-color: #000;
}
.navbar button:focus{
	box-shadow: none;
}

.fixed-top{
	position: fixed;
}
.navBackgroundScroll{
    background-position:left right;
	background-color: #fff;
	box-shadow: 6px 5px 30px 0px rgb(0 0 0 / 12%);
    transition: 0.8s;
}
.navBackgroundScroll .navbar-nav .nav-link{
	color: #000;
}
.navBackgroundScroll button{
	background-color: #FF4A17;
}

.navBarBackgroundColor{
    background-color: #000;
}

/*============Slider component css============*/
.header_slider_area{
    position: relative;
    overflow: hidden;
}
.carousel-caption{
    left: 18%;
    text-align: left;
    top: 33%;
}
.carousel-caption h3{
    font-family: "Nunito Sans";
    text-transform: uppercase;
    line-height: 24px;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 23px;
}
.carousel-caption h1{
    font-family: inherit;
    line-height: 80px;
    font-weight: 700;
    font-size: 70px;
    max-width: 800px;
    margin-bottom: 38px;
}
.learn_more{
    font-size: 15px;
    text-transform: uppercase;
    background: #FF4A17;
    border: 1px solid #FF4A17;
    padding: 17px 24px;
    font-weight: 600;
    margin-right: 22px;
}
.learn_more:hover{
    background-color: transparent;
    border: 1px solid #fff;
}
.touch{
    font-size: 15px;
    text-transform: uppercase;
    background: transparent;
    padding: 17px 24px;
    font-weight: 600;
    border: 1px solid #fff;
}
.touch:hover{
    background-color: #fff;
    color: #000;
}
.learn_more:focus, .touch:focus{
    border-color: none;
}
.yellow_animation{
    position: absolute;
    top: 90px;
    right: -23%;
    animation: upDown 2.9s infinite alternate;
}
.border_animation_left{
    position: absolute;
    top: -23px;
    left: -28%;
    animation: slideUpDown 2.7s infinite alternate;
}
.border_animation_right{
    position: absolute;
    bottom: 10%;
    right: -25%;
    animation: slideUpDown 3.0s infinite alternate;
}
.logo_back_animation{
    position: absolute;
    top: -30%;
    left: -9%;
}
@keyframes slideUpDown {
    0% {
        transform: translateY(-10px); 
    }
    100% {
        transform: translateY(40px); 
    } 
}

/*=============About Company css start================*/
.about_company_header{
	margin-bottom: 62px;
}
.about_header_left{
	background-color: #14212B;
	padding: 40px 0px
}
.about_header_right{
	background-color: #FF4A17;
	padding: 40px 0px;
}
.about_header_left_content h3{
	margin: 0% 0% 0% 18.6%;
    color: #ffffff;
    font-size: 30px;
    position: relative;
    z-index: 1;
    transition: .4s;
    font-family: "inherit", Sans-serif;
    font-weight: 700;
    float: left;
}
.about_header_right_content h3{
	margin: 0% 0% 0% 18.6%;
    color: #ffffff;
    font-size: 30px;
    position: relative;
    z-index: 1;
    transition: .4s;
    font-family: "inherit", Sans-serif;
    font-weight: 700;
    float: left;
}
.about_header_left_content > span,.about_header_right_content > span{
	color: #2A3843;
	margin: 0% 0% 0% 10.4%;
    padding: 16px 0px 0px 0px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 100px;
    line-height: .9;
    letter-spacing: -.05em;
    z-index: 0;
    pointer-events: none;
    transition: .4s;
    font-family: "inherit", Sans-serif;
    font-weight: 700;
}
.about_header_right_content > span{
	color: #FF673C; 
}
.about_icon{
	float: right;
    margin-right: 74px;
    position: relative;
    cursor: pointer;
}
.about_icon > span{
	width: 40px;
	height: 40px;
	border: 1px solid currentColor;
	border-radius: 3px;
	transition: all 0.5s, color .4s;
	display: block;
	color: rgba(255,255,255,.4);
	position: absolute;
	top: -3px;
	left: -29px;
}
.about_header_left:hover .about_icon span, .about_header_right:hover .about_icon span{
	transform: translateX(100%);
}
.about_icon svg{
	font-size: 35px;
    color: #fff;
}
.aboutCompany_area{
	box-shadow: 6px 5px 30px 0px rgb(0 0 0 / 12%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding-bottom: 100px;
    margin-bottom: 120px;
}

.about_company_content h4{
	color: #ff4a17;
	font-family: "Nunito Sans", Sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 14px;
}
.about_company_content h4::after{
	content: "";
    position: absolute;
    width: 12px;
    height: 3px;
    background-color: #FF4A17;
    top: 11px;
    margin-left: 5px;
}
.about_company_content h1{
	color: #222222;
	font-family: inherit;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 16px;
}
.about_company_content p{
	font-size: 18px;
    line-height: 32px;
    color: #616161;
    font-family: "Nunito Sans", Sans-serif;
    font-weight: 400;
    margin: 0 0 30px 0;
}
.company_solution blockquote{
	font-family: inherit;
    color: #222222;
	display: inline-block;
    position: relative;
    z-index: 1;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    padding: 34px 70px;
    background: #f5f5f5;
    border-radius: 0 10px 10px 0;
    width: 100%;
    overflow: hidden;
    border-left: 3px solid #FF4A17;
}
.about_image_content{
	position: relative;
}
.about_image_content img{
	width: 100%;
}
.about_main_img img{
	width: 100%;
}
.about_border_img{
	position: absolute;
    top: 0;
    animation-duration: 2.9s;
    animation: leftRight 2s infinite alternate;
}
@keyframes leftRight {
	0% {transform: translateX(-10px);}
	100% {transform: translateX(10px);} 
}
.about_border_img img{
	width: 100%;
}
.all_img_ani_duration{
	transition: all 0.5s;
	cursor: pointer;
}
.about_twiter_img{
	position: absolute;
    top: 0;
    left: 0;
    animation: upDown 2.5s infinite alternate;
}
.about_fb_img{
	position: absolute;
    top: 0;
    left: 0;
    animation: upDown 2.7s infinite alternate;
}
.about_Ins_img{
	position: absolute;
    top: 0;
    left: 0;
    animation: upDown 2.9s infinite alternate;
}
@keyframes upDown {
	0% {
		transform: translateY(-10px); 
	}
	100% {
		transform: translateY(10px); 
	} 
}

/*=============About Company css start================*/
.services_area{
    margin-bottom: 50px;
}
.services_header{

}
.services_header h4{
    position: relative;
    color: #ff4a17;
    font-family: "Nunito Sans", Sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
}
.services_header h4:after{
    content: "";
    background-color: #ff4a17;
    width: 17px;
    height: 3px;
    position: absolute;
    bottom: 9px;
    margin-left: 5px;
}
.services_header h4:before{
    content: "";
    background-color: #ff4a17;
    width: 17px;
    height: 3px;
    position: absolute;
    bottom: 9px;
    margin-left: -22px;
}
.services_header h1{
    color: #222222;
    font-size: 48px;
    font-family: inherit;
    font-weight: 700;
    line-height: 72px;
    margin: 0px;
}
.services_header p{
    color: #616161;
    padding: 0px 138px;
    font-size: 18px;
    line-height: 25px;
}
.service_icon{
    width: 85px;
    height: 85px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: -45px;
    left: 14%;
    box-shadow: 6px 5px 16px 0px rgb(0 0 0 / 15%);
}
.service_icon svg{
    color: #F03759;
    font-size: 45px;
    margin-top: 19px;
    margin-left: 26px;
}
.service_icon img{
    width: 65px;
    margin-left: calc(100% - 75px);
    margin-top: calc(100% - 76px);
}
.service_content_row{
    margin-top: 52px;
}
.services_content{
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 60px;
    cursor: pointer;
}
.services_content img{
    border-radius: 15px;
}
.services_content h1{
    margin: 0px 0px 15px 0px;
    font-size: 36px;
    line-height: 1.2em;
    color: #FFFFFF;
    padding: 84px 0px 4px 26px;
    font-family: inherit;
    font-weight: 700;
    position: absolute;
    top: 0;
}
.services_content p{
    font-size: 16px;
    font-family: "Nunito Sans", Sans-serif;
    font-weight: 400;
    color: #000;
    text-align: left;
    padding: 35px 25px 0px 22px;
    position: absolute;
    bottom: -21px;
    transition: .5s;
    height: 0px;
    overflow: hidden;
}
.serice_arrow_icon{
    padding-bottom: 28px;
    position: absolute;
    bottom: 0;
}
.serice_arrow_icon svg{
    font-size: 35px;
    color: #fff;
    margin-left: 38px;
}
.service_overlay{
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 0;
    transition: .5s ease;
    border-radius: 12px;
}
.services_content:hover .service_overlay{
    opacity: 1;
    box-shadow: 6px 5px 30px 0px rgb(0 0 0 / 12%);
}
.services_content:hover h1{
    color: #000;
}
.services_content:hover .serice_arrow_icon svg{
    display: none;
}
.services_content:hover p{
    height: auto;
    color: #000;
    bottom: 50px;
}

/*===============Why Choose component css=============*/
.choose_area{
    margin-bottom: 110px;
}
.choose_left_content{
    background-position: center right !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    box-shadow: 6px 5px 30px 0px rgb(0 0 0 / 12%);
    border-top-right-radius: 15px;
}
.choose_left_content img{
    width: 80%;
    padding: 238px 0px 0px 0px;
    margin-bottom: -55px;
}
.choose_right_content{
    background-color: #15222B;
    padding: 100px 0px;
    margin-top: 35px;
    border-bottom-left-radius: 10px;
}
.choose_right_content h4{
    position: relative;
    color: #ff4a17;
    font-family: "Nunito Sans", Sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    padding: 0px 0px 0px 65px;
}
.choose_right_content h4::after{
    content: "";
    background-color: #ff4a17;
    width: 17px;
    height: 3px;
    position: absolute;
    bottom: 4px;
    margin-left: 5px;
}
.choose_right_content h1{
    color: #fff;
    font-size: 48px;
    font-family: inherit;
    font-weight: 700;
    line-height: 71px;
    margin: 20px 0px;
    padding: 0px 261px 0px 65px;
}
.choose_right_content p{
    color: #fff;
    padding: 0px 0px 0px 65px;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 38px;
}
.choose_sub_content{
    padding: 0px 0px 0px 65px;
    position: relative;
}
.choose_correct svg{
    color: #fff;
    background: #FF4A17;
    padding: 12px;
    font-size: 47px;
    border-radius: 50%;
}
.choose_step p{
    padding: 0px 0px 0px 0px;
}
.choose_step ul{
    list-style: none;
    padding-left: 0;
    margin-left: 14px;
}
.choose_sub_content img{
    position: absolute;
    bottom: -100px;
    width: 56%;
    right: 0;
}

/*============Principal component css============*/
.principles_content_area{
    display: flex;
    margin-top: 80px;
}
.principles_content_left{
    flex: 30%;
    margin-left: 45px;
}
.principles_content_right{
    flex: 30%;
    margin-right: 45px;
}
.principles_content_middle{
    flex: 50%;
    position: relative;
    margin-top: 0px;
}
.princi_content_row{
    padding: 18px 14px 18px 15px;
    box-shadow: 6px 5px 30px 0px rgb(0 0 0 / 12%);
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 74px;
}
.princi_right_third{
    margin-right: -45px;
    margin-left: 45px;
}
.princi_left_third{
    margin-left: -45px;
    margin-right: 45px;
}
/*.principles_content_right .princi_content_row{
    margin-left: 0px;
}*/
.princi_content_row:hover{
    background-color: #14212B;
}
.princi_content h4{
    font-family: inherit;
    font-weight: 700;
    font-size: 24px;
}
.princi_content p{
    font-family: "Nunito Sans", Sans-serif;
    font-weight: 400;
    font-size: 16px;
    transition: 0.4s;
}
.princi_icon svg{
    font-size: 35px;
    color: #FD4816;
}

.princi_content_row:hover h4, .princi_content_row:hover p{
    background-color: #14212B;
    color: #fff;
}
.princi_img_ani_2{
    position: absolute;
    width: 100%;
    top: 0;
}
.princi_img_ani_3{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}
.princi_img_ani_4{
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0;    
}
.princi_img_ani_5{
    position: absolute;
    width: 100%;
    top: 0px;
}
.princi_img_ani_6{
    position: absolute;
    width: 100%;
    top: 0px;
}

/*===========================countsUp component css================*/

.countSection_area{
    position: relative;
    background-color: #14212B;
    margin-top: 100px;
    margin-bottom: 150px;
    padding: 65px 0px;
    border-radius: 15px;
}
.count_up_bg{
    position: absolute;
    top: -94px;
    width: 48%;
    left: 24%;
}
.count_up_right{
    position: absolute;
    width: 23%;
    bottom: -46px;
    right: -45px;
    z-index: -1;
    transform: translateX(-25px);
    transition: transform 0s linear 0s;
    will-change: transform;
}
.count_col{
    position: relative;
}
.countNumber{
    color: #ff4a17;
    display: inline-flex;
    font-size: 72px;
    line-height: 1;
    font-weight: 700;
    justify-content: center;
    letter-spacing: -.05em;
    position: relative;
    margin-bottom: 15px;
}
.countTitle{
    color: #fff;
    font-family: Nunito Sans;
    font-size: 14px;
    line-height: 1.75;
    font-weight: 800;
    text-transform: uppercase;
}
.count_col svg{
    position: absolute;
    top: -11px;
    margin-left: 8px;
    font-size: 18px;
    color: #475763;
}

/*===========================Our vission component css================*/
.our_vision{
    margin-bottom: 100px;
    max-width: 1350px;
}
.vision_img{
    position: relative;
}
.visionTwo{
    position: absolute;
    top: 0px;
    left: 0;
    animation: leftRight 2s infinite alternate
}
.visionThree{
    position: absolute;
    top: 0;
    left: 0;
    animation: leftRight 2s infinite alternate
}
.visionFour{
    position: absolute;
    top: 0px;
    left: 0;
    animation: leftRight 2s infinite alternate
}
.visionFive{
    position: absolute;
    top: 0px;
    left: 0;
    animation: leftRight 2s infinite alternate
}
.visionSix{
    position: absolute;
    top: 0px;
    left: 0;
    animation: upDown 2s infinite alternate;
}
.visionSeven{
    position: absolute;
    top: 0px;
    left: 0;
    animation: upDown 2.7s infinite alternate;
}
.vision_content{
    margin-top: 35px;
}
.vision_content h4{
    position: relative;
    color: #ff4a17;
    font-family: "Nunito Sans", Sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    padding: 0px 0px 0px 65px;
}
.vision_content h4::after {
    content: "";
    background-color: #ff4a17;
    width: 17px;
    height: 3px;
    position: absolute;
    bottom: 4px;
    margin-left: 5px;
}
.vision_content h1{
    color: #222222;
    font-size: 48px;
    font-family: inherit;
    font-weight: 700;
    line-height: 60px;
    margin: 0px;
    padding: 0px 0px 0px 65px;
}
.vision_content p{
    color: #616161;
    padding: 0px 95px 15px 65px;
    font-size: 18px;
    line-height: 30px;
}
.progressbar{
    padding: 0px 65px 25px 65px;
}
.progressbar h6{
    text-transform: uppercase;
    color: #222222;
    line-height: inherit;
    font-weight: 700;
    font-size: 14px;
}
.progressbar label{
    text-transform: uppercase;
    color: #222222;
    line-height: inherit;
    font-weight: 700;
    font-size: 14px;
    width: 100%;
    text-align: right;
    margin-top: -29px;
    display: block;
}
.progressbar span{
    width: 100%;
    height: 1px;
    background: #DBDBDB;
    display: block;
    margin-top: 10px;
}   
.progress-bar {
    background-color: #ff4a17;
    transition: width .6s ease;
    height: 7px;
}
.progress {
    height: 7px;
}

/*===========================Partners component css================*/

.partners_area{
    max-width: 1662px;
    margin: 136px auto;
}
.partners_area .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 22px;
}
.partners_area .owl-carousel .owl-item img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: 0 auto;
    transition: .5s;
}
.partners_area .owl-carousel .owl-item:hover img{
    transform: scale(1.2);
    cursor: pointer;
}
.partners_area .owl-theme .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
    margin: 5px 7px;
    background: #D6D6D6;
}
.partners_area button:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}
.partners_area .owl-item{
    margin-top: 22px;
    height: 100px;
    padding-top: 28px;
    margin-bottom: 22px;
}
.partners_area .owl-item:hover {
    height: 100px;
    box-shadow: 6px 5px 30px 0px rgb(0 0 0 / 12%);
    padding-top: 28px;
    border-radius: 11px;
}

/*===========================Comments component css================*/
.comments_area{
    padding: 82px 0px;
    background-position: center !important;
    background-size: cover !important;
}
.comments_area .services_header {
    margin-bottom: 60px;
}
.comments_area .owl-carousel .owl-item{
    background-color: #fff;
    box-shadow: 6px 5px 20px 0px rgb(0 0 0 / 12%);
    padding: 28px 0px;
    border-radius: 15px;
}
.quote_icon_svg svg{
    width: 55px;
}
.quote_icon_svg svg .st0, .quote_icon_svg svg .st0 {
    fill: #ff4a17;
}
.comments_area p{
    color: #616161;
    position: relative;
    font-weight: 300;
    font-size: 21px;
    line-height: 1.5;
    margin-top: 23px;
    margin-bottom: 30px;
    padding: 0px 148px;
}
.comments_area h5{
    font-family: inherit;
    font-weight: 700;
    color: #222222;
    font-size: 24px;
    line-height: 1.09;
    transition: 0.4s;
    margin: 0px;
    text-transform: uppercase;
}
.comments_area h6{
    padding: 10px 0px 0px 0px;
    color: #989898;
    display: block;
    font-size: 14px;
    line-height: 1.25;
    font-weight: 700;
    text-transform: uppercase;
}

.comments_area .owl-theme .owl-nav {
    position: absolute;
    top: 30%;
    width: 100%;
}
.comments_area .owl-prev{
    position: absolute;
    left: 0px;
}
.comments_area .owl-prev span{
    font-size: 85px;
    padding-left: 28px;
}
.comments_area .owl-next span{
    font-size: 85px;
    padding-right: 28px;
}
.comments_area .owl-next{
    position: absolute;
    right: 0px;
}
.comments_area .owl-theme .owl-nav [class*='owl-']:hover {
    background: transparent;
    color: #000;
    text-decoration: none;
}
.comments_area button:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}
.comments_area .owl-carousel .owl-item img {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 50%;
    margin-bottom: 24px;
}

/*================footer component css==============*/
.footer_content{
    background-color: #0D1820;
    color: #fff;
    font-family: "Nunito Sans", Sans-serif;
    font-weight: 400;
    font-size: 16px;
    padding: 100px 0px 20px 0px;
}
.footer_col_one ul{
    list-style: none;
}
.footer_col_one ul li{
    display: table;
    margin-bottom: 28px;
}
.footer_col_one ul li svg{
    font-size: 28px;
    margin-right: 20px;
    float: left;
}
.mapmarker{
    margin-top: 22px;
}
.footer_col_two ul{
    list-style: none;
}
.footer_col_two ul li{
    padding-bottom: 15px;
}
.footer_col_two ul li a{
    color: #fff;
}
.footer_map iframe{
    width: 100%;
    height: 215px;
    filter: brightness( 75% ) contrast( 200% ) saturate( 0% ) blur( 0px ) hue-rotate( 0deg);
    border-radius: 15px;
}
.footer_bottom_content{
    background-color: #0D1820;
    border-top: 1px solid #888585;
    padding: 10px 0px;
}
.footer_bottom_content p{
    font-size: 20px;
    color: #fff;
    text-align: right;
    margin: 0px;
}
.pageArea{
    margin-top: 12px;
    margin-bottom: 35px;
}
.detailsButton a{
    font-size: 18px;
    color: #fff;
    background: #EF4523;
    padding: 4px 22px;
    margin-top: 1px;
    display: inline-block;
    border-radius: 5px;
}