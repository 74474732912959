/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 430px) {
    /*=============Slider area css========*/
    .fixed-top {
        background: #ddd;
    }
    .fixed-top {
        position: relative;
    }
    .navbar-brand {
        width: 48%;
    }
    .carousel-caption h1 {
        line-height: 22px;
        font-size: 18px;
        margin-bottom: 10px;
    }
    .carousel-caption h3 {
        line-height: 19px;
        font-size: 13px;
        margin-bottom: 2px;
    }
    .learn_more {
        font-size: 12px;
        padding: 6px 5px;
        margin-right: 10px;
    }
    .touch {
        font-size: 12px;
        padding: 5px 12px;
    }
    .carousel-indicators {
        display: none;
    }

    /*=============About Company css start================*/ 
    .about_header_left_content h3 {
        margin: 2% 0% 0% 3%;
        font-size: 15px;
    }
    .about_header_right_content h3 {
        margin: 2% 0% 0% 3%;
        font-size: 15px;
    }
    .services_header p {
        padding: 0px 0px;
    }
    .services_header h1 {
        font-size: 28px;
    }

    /*===============Why Choose component css=============*/

    .choose_left_content img {
        width: 80%;
        padding: 145px 0px 0px 0px;
        margin-bottom: 0px;
    }
    .choose_right_content h1 {
        font-size: 33px;
        line-height: 47px;
        padding: 0px 10px 0px 10px;
    }
    .choose_right_content p {
        color: #fff;
        padding: 0px 10px 0px 10px;
        font-size: 17px;
        line-height: 26px;
        margin-bottom: 38px;
    }
    .choose_correct svg {
        padding: 5px;
        font-size: 31px;
    }
    .choose_sub_content {
        padding: 0px 0px 0px 10px;
    }
    /*============Principal component css============*/
    .logo_back_animation {
        display: none;
    }
    .yellow_animation {
        display: none;
    }
    .border_animation_right {
        display: none;
    }
    .border_animation_left {
        display: none;
    }
    .carousel-caption {
        left: 20%;
        text-align: left;
        top: 7%;
    }
    .principles_content_area {
        display: flex;
        flex-direction: column;
    }

    .princi_content_row {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 40px;
    }
    .principles_content_middle {
        flex: 15%;
        margin: 0 auto 40px;
    }
    .principles_content_right {
        flex: 30% 1;
        margin-right: 0px;
    }
    .principles_content_left {
        margin-left: 0px;
    }

/*===========================Our vission component css================*/

    .vision_content h1 {
        font-size: 30px;
        line-height: 38px;
        padding: 0px 0px 0px 10px;
    }
    .vision_content p {
        padding: 0px 10px 15px 10px;
        font-size: 16px;
    }
    .progressbar {
        padding: 0px 10px 25px 10px;
    }
    .our_vision {
        margin-bottom: 0px;
    }
    .partners_area {
        margin: 18px auto;
    }
    .comments_area p {
        font-size: 16px;
        padding: 0px;
    }
/*===========================footer responsive css================*/
    .footer_content {
        padding: 40px 0px 20px 0px;
    }
    .footer_bottom_content p {
        font-size: 18px;
        color: #fff;
        text-align: center;
        margin: 0px;
    }
    .footer_bottom_content{
        text-align: center;
    }
    .footer_bottom_content img{
        width: 55%;
    }
    .footer_col_two{
        border-top: 1px solid #fff;
        padding-top: 20px;
    }
    .choose_step{
        margin-left: 13px;
    }
}

@media (min-width: 431px) and (max-width: 575px) { 
        /*=============Slider area css========*/
        .fixed-top {
            background: #ddd;
        }
        .fixed-top {
            position: relative;
        }
        .navbar-brand {
            width: 48%;
        }
        .carousel-caption h1 {
            line-height: 22px;
            font-size: 18px;
            margin-bottom: 10px;
        }
        .carousel-caption h3 {
            line-height: 19px;
            font-size: 13px;
            margin-bottom: 2px;
        }
        .learn_more {
            font-size: 12px;
            padding: 6px 5px;
            margin-right: 10px;
        }
        .touch {
            font-size: 12px;
            padding: 5px 12px;
        }
        .carousel-indicators {
            display: none;
        }

        /*=============About Company css start================*/ 
        .about_header_left_content h3 {
            margin: 2% 0% 0% 3%;
            font-size: 15px;
        }
        .about_header_right_content h3 {
            margin: 2% 0% 0% 3%;
            font-size: 15px;
        }
        .services_header p {
            padding: 0px 0px;
        }
        .services_header h1 {
            font-size: 28px;
        }

        /*===============Why Choose component css=============*/

        .choose_left_content img {
            width: 80%;
            padding: 145px 0px 0px 0px;
            margin-bottom: 0px;
        }
        .choose_right_content h1 {
            font-size: 33px;
            line-height: 47px;
            padding: 0px 10px 0px 10px;
        }
        .choose_right_content p {
            color: #fff;
            padding: 0px 10px 0px 10px;
            font-size: 17px;
            line-height: 26px;
            margin-bottom: 38px;
        }
        .choose_correct svg {
            padding: 5px;
            font-size: 31px;
        }
        .choose_sub_content {
            padding: 0px 0px 0px 10px;
        }
        /*============Principal component css============*/
        .logo_back_animation {
            display: none;
        }
        .yellow_animation {
            display: none;
        }
        .border_animation_right {
            display: none;
        }
        .border_animation_left {
            display: none;
        }
        .carousel-caption {
            left: 20%;
            text-align: left;
            top: 7%;
        }
        .principles_content_area {
            display: flex;
            flex-direction: column;
        }

        .princi_content_row {
            width: 100%;
            margin: 0 auto;
            margin-bottom: 40px;
        }
        .principles_content_middle {
            flex: 15%;
            margin: 0 auto 40px;
        }
        .principles_content_right {
            flex: 30% 1;
            margin-right: 0px;
        }
        .principles_content_left {
            margin-left: 0px;
        }

    /*===========================Our vission component css================*/

        .vision_content h1 {
            font-size: 30px;
            line-height: 38px;
            padding: 0px 0px 0px 10px;
        }
        .vision_content p {
            padding: 0px 10px 15px 10px;
            font-size: 16px;
        }
        .progressbar {
            padding: 0px 10px 25px 10px;
        }
        .our_vision {
            margin-bottom: 0px;
        }
        .partners_area {
            margin: 18px auto;
        }
        .comments_area p {
            font-size: 16px;
            padding: 0px;
        }
    /*===========================footer responsive css================*/
        .footer_content {
            padding: 40px 0px 20px 0px;
        }
        .footer_bottom_content p {
            font-size: 18px;
            color: #fff;
            text-align: center;
            margin: 0px;
        }
        .footer_bottom_content{
            text-align: center;
        }
        .footer_bottom_content img{
            width: 55%;
        }
        .footer_col_two{
            border-top: 1px solid #fff;
            padding-top: 20px;
        }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) { 

	
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
	
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {

    /*============Principal component css============*/
	.logo_back_animation {
        top: -58%;
        left: -24%;
    }
    .yellow_animation {
        top: -62px;
        right: -41%;
    }
    .border_animation_right {
        bottom: 0%;
        right: -57%;
    }
    .border_animation_left {
        top: -23px;
        left: -53%;
    }
    .carousel-caption {
        left: 18%;
        text-align: left;
        top: 24%;
    }
    .principles_content_area {
        display: flex;
        flex-direction: column;
    }

    .princi_content_row {
        width: 78%;
        margin: 0 auto;
        margin-bottom: 40px;
    }
    .principles_content_middle {
        flex: 15%;
        margin: 0 auto 40px;
    }
    .principles_content_right {
        flex: 30% 1;
        margin-right: 0px;
    }
    /*============chose section responsive css===============*/
    .choose_right_content h1 {
        padding: 0px 0px 0px 65px;
    }
    .choose_right_content p {
        padding: 0px 0px 0px 65px;
    }
    .choose_right_content {
        padding: 18px 0px;
    }
    .choose_sub_content img {
        bottom: 0;
    }
}


/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1350px) {
	.logo_back_animation {
        top: -49%;
        left: -17%;
    }
    .yellow_animation {
        top: 0px;
        right: -35%;
    }
    .border_animation_right {
        bottom: 0%;
        right: -54%;
    }
    .border_animation_left {
        top: -23px;
        left: -53%;
    }
    /*============chose section responsive css===============*/
    .choose_right_content h1 {
        padding: 0px 0px 0px 65px;
    }
    .choose_right_content p {
        padding: 0px 0px 0px 65px;
    }
    .choose_right_content {
        padding: 18px 0px;
    }
    .choose_sub_content img {
        bottom: 0;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1351px) and (max-width: 1450px) {
    .logo_back_animation {
        top: -43%;
        left: -15%;
    }
    .yellow_animation {
        top: 0px;
        right: -32%;
    }
    .border_animation_right {
        bottom: 9%;
        right: -43%;
    }
    .border_animation_left {
        top: -23px;
        left: -45%;
    }
    .choose_step{
        margin-left: 21px;
    }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1451px) and (max-width: 1650px) {
    .logo_back_animation {
        top: -39%;
        left: -13%;
    }
    .yellow_animation {
        top: 0px;
        right: -29%;
    }
    .border_animation_right {
        bottom: 10%;
        right: -35%;
    }
    .border_animation_left {
        top: -23px;
        left: -42%;
    }
    .choose_step{
        margin-left: 21px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1651px) and (max-width: 1850px) {
    .logo_back_animation {
        top: -30%;
        left: -9%;
    }
    .yellow_animation {
        top: 0px;
        right: -24%;
    }
    .border_animation_right {
        bottom: 10%;
        right: -29%;
    }
    .border_animation_left {
        top: -23px;
        left: -32%;
    }
}

